var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: "mdi-alert", title: "Não autorizado" } },
    [
      _c(
        "v-container",
        { staticClass: "text-center", attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c("h1", { staticClass: "text-h1 blue--text" }, [
                  _vm._v("Ops, 503")
                ]),
                _c("p", { staticClass: "text-h5 mt-4" }, [
                  _vm._v("Manutenção!")
                ]),
                _c("p", { staticClass: "body-1" }, [
                  _vm._v(
                    "O sistema encontra-se em manutenção. Tente novamente mais tarde."
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }